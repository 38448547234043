import { getImage } from "@/components/NotifyModal/image";
import Image from "next/image";

import styles from "./styles.module.scss";
import { RewardType } from "@/store/dailyReward/interface";

type AdsRewardType = Record<string, { icon: string; amount: number }>;
type ModalRewardsContainerPropsType = {
  className?: string;
  rewards: AdsRewardType | RewardType;
};

export const ModalRewardsContainer = ({
  className = "",
  rewards,
}: ModalRewardsContainerPropsType) => {
  console.log(
    Object.entries(rewards).map(([key, value]) => {
      console.log(value);
    })
  );
  return (
    <div className={`${styles.reward__container} ${className}`}>
      {Object.entries(rewards).map(([key, value], index) => (
        <div key={index} className={styles.reward__wrapper}>
          <Image
            src={getImage(value.icon)}
            width={48}
            height={48}
            alt={value.icon}
          />
          <span className={styles.reward__value}>{value.amount}</span>
        </div>
      ))}
    </div>
  );
};
