import PortalModal from "@/components/PortalModal";
import close from "@/assets/icons/close.png";
import gift from "@/assets/icons/popugue.png";
import Image from "next/image";
import { getTranslation } from "@/utils/location";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import ActionButton from "@/components/ActionButton";
import { clearDailyReward } from "@/store/dailyReward/dailyRewardSlice";

import styles from "../../styles.module.scss";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { ModalRewardsContainer } from "@/components/Containers/ModalRewardsContainer";

const DailyRewardLayout = () => {
  const dispatch = useDispatch();
  const ws = useSelector((state: RootState) => state.sockets.ws);
  const { day_reward, rewards } = useSelector(
    (state: RootState) => state.dailyReward
  );
  const handleClose = () => {
    sendWsEvent(ws, WebSocketsClientEvents.COLLECT_DAILY_REWARD, {
      isCollected: false,
    });
    dispatch(clearDailyReward());
  };

  const handleCollect = () => {
    sendWsEvent(ws, WebSocketsClientEvents.COLLECT_DAILY_REWARD, {
      isCollected: true,
    });
    dispatch(clearDailyReward());
  };

  return (
    <PortalModal isOpen={Boolean(day_reward)}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={`${styles.modal__title__up}`}>
          {getTranslation("ЕЖЕДНЕВНАЯ НАГРАДА")}
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={256}
            className={`${styles.modal__image} ${styles.mt_25}`}
            src={gift}
            alt="image"
          />
          <h2 className={`${styles.modal__title} ${styles.mt_25}`}>
            {getTranslation("Награда за день")} <span>{day_reward}</span>
            <p>ждём графику</p>
          </h2>

          {rewards && <ModalRewardsContainer rewards={rewards} />}

          <div className={`${styles.modal__actions} ${styles.mt_25}`}>
            <ActionButton onClick={handleCollect}>
              {getTranslation("Забрать")}
            </ActionButton>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default DailyRewardLayout;
