import PortalModal from "@/components/PortalModal";
import close from "@/assets/icons/close.png";
import gift from "@/assets/icons/popugue.png";
import Image from "next/image";
import { getTranslation } from "@/utils/location";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { ShowPromiseResult } from "@/@types/adsgram";
import { useAdsgram } from "@/hooks/useAdsgram";
import { useCallback } from "react";
import ActionButton from "@/components/ActionButton";
import { clearAds, setAdsModal, setShowModalAds } from "@/store/ads/adsSlice";
import { ModalRewardsContainer } from "@/components/Containers/ModalRewardsContainer";

import styles from "../../styles.module.scss";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";

const AdsLayout = () => {
  const dispatch = useDispatch();
  const { modal, showModal } = useSelector((state: RootState) => state.ads);
  const ws = useSelector((state: RootState) => state.sockets.ws);
  const step = modal?.type === "intro" ? 1 : 2;

  const handleClose = useCallback(() => {
    dispatch(clearAds());
    dispatch(setShowModalAds(false));
  }, [dispatch]);

  const handleCompleteAds = useCallback(() => {
    sendWsEvent(ws, WebSocketsClientEvents.ADS_COMPLETE, {});
  }, [dispatch]);

  const handleCollect = () => {
    sendWsEvent(ws, WebSocketsClientEvents.ADS_CHECKED, {});
    dispatch(setShowModalAds(false));
    dispatch(clearAds());
  };

  const onError = useCallback((result: ShowPromiseResult) => {
    console.log(JSON.stringify(result, null, 4));
  }, []);

  const showAd = useAdsgram({
    blockId: "int-6148",
    onReward: handleCompleteAds,
    onError,
  });

  const handleShowAds = useCallback(() => {
    showAd();
    dispatch(setAdsModal(false));
    dispatch(clearAds());
  }, [showAd]);

  if (!modal) return null;

  return (
    <PortalModal isOpen={showModal}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <h1 className={`${styles.modal__title__up}`}>
          {getTranslation("РЕКЛАМА")}
        </h1>
        <div className={styles.modal__content}>
          <Image
            width={256}
            className={`${styles.modal__image} ${styles.mt_25}`}
            src={gift}
            alt="gift"
          />
          <h2 className={`${styles.modal__title} ${styles.mt_25}`}>
            {step == 1
              ? getTranslation("Посмотрите награду и получите:")
              : getTranslation("Ваша награда:")}
          </h2>
          <ModalRewardsContainer rewards={modal.rewards} />
          <div className={`${styles.modal__actions} ${styles.mt_25}`}>
            {step == 1 ? (
              <ActionButton onClick={handleShowAds}>
                {getTranslation("Смотреть")}
              </ActionButton>
            ) : (
              <ActionButton onClick={handleCollect}>
                {getTranslation("Забрать")}
              </ActionButton>
            )}
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default React.memo(AdsLayout);
